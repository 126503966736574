$primary: #2D5778;
$danger: #8F412F;

$light: #eeeeee;

$link-color: $primary;
$link-hover-color: $primary;

/*
$pagination-border-color:           $primary;
$pagination-hover-color:            #FFF;
$pagination-hover-bg:               $primary;
$pagination-hover-border-color:     $primary;
$pagination-active-color:           #FFF;
$pagination-active-bg:              $primary;
$pagination-active-border-color:    $primary;
*/
$pagination-disabled-color:         #DDD;
$pagination-disabled-bg:            #FFF;
$pagination-disabled-border-color:  #DDD;

$font-family-base: "Open Sans", sans-serif;

$warning-light: #FFC980; // v3 brand-warning-light
//$success-light: #719BBB; // Light blue


/*
$black: #191919; // v3 gray-base
$info: #0C3554;   // v3 brand-info 

$light: #F8F8F8;  // v3 gray-lighter
$gray-500: #DDDDDD; // v3 gray-light

$gray-600: #4c4c4c;  // v3 gray-dark
$dark: #999999; // v3 gray
$gray-900: #131822; // v3 gray-base-light
$gray-800: #21262C; // v3 gray-base-lighter

$primary: #DC8A3D;  // v3 brand-primary 

$success: #2D5778;

$danger: #8F412F; //v3 brand-danger 

$font-family-base: "Open Sans", sans-serif;
*/


$gray-500: #DDDDDD; /*v3 gray-light*/
$gray-600: #4c4c4c;  /*v3 gray-dark*/
$gray-700: #3b3b3b; /*v3 gray-darker*/
$gray-800: #21262C; /*v3 gray-base-lighter*/
$gray-900: #131822; /*v3 gray-base-light*/


//$navbar-dark-color: $warning-light;