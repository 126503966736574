@import "customizebootstrap";

@import "bootstrap-5.1.3/scss/bootstrap";

@import "helpers/_lightbox";

@import "ng-select/default.theme.scss";


.alert-info {
    color: $primary;
}


$eh-orange-light: #FFC980;
$eh-orange: #DC8A3D;

$eh-dark: #131822;

$eh-plan-starter: $success;
$eh-plan-bronze: #B08D57;
$eh-plan-silver: #7C8288;
$eh-plan-gold: #DAA520;
$eh-plan-platinum: #8993A5;
$eh-plan-enterprise: #E89024;

//$eh-gray-lighter: #EEEEEE;
//$eh-gray: #EEEEEE;
//$eh-gray-darker: #EEEEEE;

html, body{
    margin: 0;
    padding: 0;
    height: 100%;
    color: #262626;
}

p{
    max-width: 1200px;
}

a{
    text-decoration: none;  
}
a:hover  {
    text-decoration: underline;  
}

.btn-link{
    text-decoration: none;
}
.btn-link:hover{
    text-decoration: underline;
}

.breadcrumb{
    background-color: #e9ecef;
    padding: .75rem 1rem;
}

.badge.bg-primary{
    background-color: $primary;
}

.text-white{
    color: $white;
}

.text-danger{
    color: $danger;
}

.container-fluid{
    @include media-breakpoint-up(md){
        padding-right: (($grid-gutter-width * 2) / 2);
        padding-left: (($grid-gutter-width * 2) / 2);
    }
    @include media-breakpoint-up(lg){
        padding-right: (($grid-gutter-width * 3) / 2);
        padding-left: (($grid-gutter-width * 3) / 2);
    }
    @include media-breakpoint-up(xl){
        padding-right: (($grid-gutter-width * 4) / 2);
        padding-left: (($grid-gutter-width * 4) / 2);
    }
}

.main-container{
    min-height:100%;
    position:relative;
    padding-bottom:250px;   /* Height of the footer  294px*/
}
.main-container-logged-in{
    min-height:100%;
    position:relative;
    padding-bottom:1rem;
}

.content-container{
    padding-top: 46px;
}
/*
.content-container{
    @include media-breakpoint-up(md){
        padding-top: 46px;
    }
}
*/

.title-container{
    background-color: $light;
    border-bottom: 1px solid $gray-500;
    margin-bottom: 24px;
    padding: 16px 0 10px 0;

    .eh-page-heading{
        margin: 10px 0 10px;
    }
}

.eh-plan-starter{
    color: $eh-plan-starter;
}

.eh-plan-bronze{
    color: $eh-plan-bronze;
}

.eh-plan-silver{
    color: $eh-plan-silver;
}

.eh-plan-gold{
    color: $eh-plan-gold;
}

.eh-plan-platinum{
    color: $eh-plan-platinum;
}

.eh-plan-enterprise{
    color: $eh-plan-enterprise;
}

.col-form-label{
    font-weight: bold;
}

.invalid-feedback {
    display: block;
}

.card-header, .card-footer {
    background-color: #f4f4f4;
}

hr{
    margin-top: 0.5rem;
}

p.eh-field-value {
    overflow: hidden;
}

.fa-ellipsis-v {
    min-width: 20px;
    cursor: pointer;
}

.eh-fa-icon-sm {
    display: inline-block;
    margin-right:6px;
    width:20px;
}

.btn-eh-orange {

    //border-color: $eh-orange-light; 
    background-color: $eh-orange-light;
    color: $eh-dark; 
    margin: 0rem 0.5rem ;
    &:focus {
        background-color: $eh-orange !important;
    }
    &:active, 
    &:hover {
        border-color: #b86a21; 
        background-color: $eh-orange;
        color: $white;
    }
    //&:focus {
    //    outline: #b86a21; 
    //    box-shadow: $btn-focus-box-shadow;
    //}
}

.badge-eh-orange {
    background-color: $eh-orange;
}

.eh-dropdown-menu .eh-fa-icon-sm, .eh-tools-menu .eh-fa-icon-sm, .eh-profile .eh-fa-icon-sm {
    min-width: 18px;
    text-align: left;
}

.eh-tools-menu{
    .btn-link{
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}

.eh-profile .eh-fa-icon-md {
    min-width: 24px;
    text-align: left;
}

div.eh-visitor-admin-indent {
    text-indent: 30px;
}

svg.eh-icon-link path {
	fill: #191919;
}


form.eh-order-up-form {
    display: inline;

    .btn-lg {
        padding: 4px;
    }
}

form.eh-order-down-form {
    display: inline;

    .btn-lg {
        padding: 4px;
    }    
}

.confirm-delete-dialog {
    display:none;

    .eh-delete-submit {
        margin-left: 20px;
    }
}

.deleteVideoForm, .deleteAudioForm {
    .confirm-delete-dialog {
        display:none;
        background-color: rgba(222, 215, 215, 0.4);
    }
}

.eh-page-heading{
    margin: 20px 0px 25px;
    color: $black;

    h1{
        margin: 0px;
        font-size: 1.7em;
    }
}

div.eh-dropdown-option {
	padding-left: 8px;
	padding-right: 6px;
	color: $success;
	cursor: pointer;
}

.tooltip-inner {
    min-width: 250px; //the minimum width
    text-align: left;
}

img[src=''], img:not([src]){
    opacity:0;
}

.table-responsive {
    .table td{
        white-space: nowrap;
    }
}

.eh-page-header {

    .form-group{
        width: 100%;
    }

    p{
        margin-bottom: 0px;
    }

    label{
        float: left;
    
        /*Component specific break point*/
        @media (max-width: 350px){
            width: 100%;
            margin-top: 7px;
        }
        @media (min-width: 351px){
            width: 10%;
            margin-top: 7px;
        }
    }

    #sort{

        float: left;
    
        /*Component specific break point*/
        @media (max-width: 350px){
            width: 100%;
            margin: 0px 5px 0px 0px;
        }
        @media (min-width: 351px){
            width: 40%;
            margin: 0px 5px 0px 0px;
        }
    
    }

    #order{

        float: left;
    
        /*Component specific break point*/
        @media (max-width: 350px){
            width: 100%;
            margin: 10px 5px 0px 0px;
        }
        @media (min-width: 351px){
            width: 20%;
            margin: 0px 5px 0px 0px;
        }
    }

    button{

        float: left;
    
        /*Component specific break point*/
        @media (max-width: 350px){
            width: 100%;
            margin: 10px 0px;
        }
        @media (min-width: 351px){
            width: 20%;
            margin: 0px;
        }
    }
}

.eh-disclaimer{
    margin-top: 30px;
    font-size: 0.9em;

    h6{
        color: $dark;
        font-weight: bold;
    }

    p{
        color: $dark;
        font-size: 0.9em;
        margin: 0;
    }
}

/* Pagination start */

.ehive-pagination{
    display: block;
    @include media-breakpoint-down(sm){
        text-align: center;
    }

    .pagination{
        @include media-breakpoint-up(md){
            float: right;
        }

        li {
            margin-right: 0.2rem;
            &:last-child {
                margin-right: 0;
            }
            a,span{
                @media(max-width: 420px){
                    margin-top: 10px;
                } 
            }
        }
    }
}

.navigation-container{
    background-color: $gray-900;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);-moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);-webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);

    .navbar{
        background-color: $gray-900;
        margin-bottom: 0;
        border: 0;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;

        .dropdown-divider {
            border-top: 1px solid $gray-400;
        }

        .navbar-toggler {
            color: white;
        }  
        
        .dropdown-item {
            color: $eh-dark !important;
        }
    }

    //.navbar-brand, .eh-dev {
    //    margin-right: 0;
    //    img {
    //        margin-top: -8px;
    //    }
    //}

    a.navbar-brand {

        //font-size: 1.25rem;
        img {
            margin-top: -6px;
        }
    }

    a.navbar-brand.eh-login {
        background-color: #131822;
        text-decoration: none;
    }

    a.navbar-brand.eh-logged-in {
        background-color: $eh-orange-light;
        color: $eh-dark;
        text-decoration: none;   
        &:active, 
        &:hover {
            border-color: #b86a21; 
            background-color: $eh-orange;
            color: $white;
        }
    }
     
    a.nav-link{
        font-size: 1.1em;
        color: $eh-orange-light;
    }   

    .navbar-nav{

        .nav-link{
            color: $eh-orange-light;
            &:active , &:hover{
                color: $white;  
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-down(lg){ 
        .navbar-nav{
            .nav-link {
                color: #fff; // !important;

                &:active, &:hover{
                    color: $eh-orange-light;  
                }
            }
        }
    }
    
    .eh-nav-search{
        height: 30px;
        border-radius: 2px 0px 0px 2px;
        width: 80% !important;
        float: right !important;
        padding: 0px 12px;
    }
    
    .eh-nav-search:focus{
        border:1px solid #ccc !important;
    }
    
    .eh-nav-search-submit{
        height: 30px;
        border: 1px solid #ccc;
        padding: 0 10px;
        border-radius: 0 2px 2px 0;
        background-color: #fff !important;
        color: $gray-600;
    }
    
    .eh-nav-search-submit:hover, .eh-nav-search-submit:focus{
        background-color: #fff !important;
        color: $gray-900 !important;
        border: 1px solid #ccc;
    }   
    
    .eh-sign-up{
        //margin-right: 0px;
        border-radius: 4px;
        padding: 4px 15px;
        background-color: $warning-light;
        color: $gray-900;
        
        &:hover {
            background-color: #DC8A3D;
            color: white;
        }
    }    
}


/*Navigation start*/
/*
.navigation-container {    

    .navbar {

        a {
            font-size: 1.1em;
        }

        .btn {
            font-size: 1.1em;
        }

        .btn-eh-orange {
            background-color: $eh-orange-light;
            color: $eh-dark; 
            margin: 0rem 0.5rem ;
            &:focus {
                background-color: $eh-orange !important;
            }
            &:active, 
            &:hover {
                border-color: #b86a21; 
                background-color: $eh-orange;
                color: $white;
            }
        }

        .dropdown {

            .dropdown-menu {
                border: 1px solid $gray-400;
                box-shadow: 1px 1px $gray-300;

                background-color: $gray-200;
                color: $gray-800;

                .dropdown-item {
                    color: $gray-800;
                }
                .dropdown-item:hover {
                    background-color: $gray-600 !important;
                    color: $white !important;
                }

                .dropdown-divider {
                    border-top: 1px solid $gray-400;
                }
            }
        }
    }
}
*/
/*
.navigation-container {

    background-color: $eh-dark;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);-moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);-webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-up(md){
        position: fixed;
        width: 100%;
        z-index: 4;
    }

    .navbar{
        margin-bottom: 0px;
        border: 0px;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;

        
        //a {
        //    font-size: 1.1em;
        //}

        //.btn {
        //    font-size: 1.1em;
        //}
        

        @include media-breakpoint-down(sm){
            border-bottom: 1px solid $dark;
            display: block; 
            // padding: 0; 
        }

       
    }

    .navbar-header{

        padding-right: 20px;

        @include media-breakpoint-down(sm){
            padding-bottom: 5px;
            padding-top: 5px;
            width: 100%;
            border-bottom: 1px solid $dark;
        }

        img{
            padding: 5px 0;
            margin-top: -5px;

            @include media-breakpoint-down(sm){
                margin-left: 15px;
                margin-top: 0;
            }
        }

        div.btn-default{
            color: $white;
            margin: 0rem 0.5rem ;
        }
    }
    
    img.eh-home-image {
        margin-right: 6px;
    }
    
    .navbar-toggler{
        border-color: #FFF;
        float: right;
    }
    
    
    //.dropdown-item:hover {
    //    background-color: #f2f2f2 !important;
    //    color: $gray-800 !important;
    //    //border-top: 1px solid #ccc;
    //    //border-bottom: 1px solid #ccc;            
    //}
    

    
    //.dropdown-item:focus {
    //    background-color: #a3a3a3 !important;
    //    color: $gray-800 !important;
    //}
    

    .navbar-nav, .eh-logged-in-user-navbar{

        @include media-breakpoint-down(sm){
            width: 100%;
            display: block;
        }

        li{

            @include media-breakpoint-down(sm){
                //border-bottom: 1px solid $gray-500;
            }

            a{
                //font-size: 1.1em;
                //@include media-breakpoint-down(sm){
                //    color: #fff !important;
                //}
            }

            .active a{
                @include media-breakpoint-down(sm){
                    color: $primary !important;
                    background-color: $gray-900 !important;
                }
            }

            //&:first-child{
            //    @include media-breakpoint-down(sm){
            //        border-right: 1px solid $dark;
            //    }
            //}
            
        }
        
        .eh-user-name{

            span{
                font-size: 80%;
                font-weight: 400;
            }

        }

        .nav-link {
            //color: $warning-light;
            color: $eh-orange-light;

            // font-size: 1.1em; 

            &:active , &:hover{
                color: $white;  
            }

            @include media-breakpoint-down(sm){
                padding-left: 1rem;
                color: #fff !important;
            }
        }


        
        //.dropdown-menu{

        //    background-color: $gray-200;

        //    @include media-breakpoint-down(sm){
        //        background-color: $gray-900;
        //    }

        //    .dropdown-item {
        //        font-size: 1.1em;
        //        color: $white;

                
        //        @include media-breakpoint-up(md){
        //            color: $gray-600;
        //        }
                
        //    }
        //}
        

        
        //.eh-my-ehive-menu-item{
        //    color: $eh-orange-light;
        //} 
             
    }
   
    
    .navbar-nav{
        li {
            @include media-breakpoint-down(sm){
                display: inline-block;
                width: 50%;
                text-align: center;
                float:left;
            }
        }
    }
   
   
    .eh-nav-search{
        height: 30px;
        border-radius: 2px 0px 0px 2px;
        width: 80% !important;
        float: right !important;
        padding: 0px 12px;

        &:focus {
            border:1px solid #ccc !important;
        }
    }

    
    .eh-nav-search-submit{
        height: 30px;
        border: 1px solid #ccc;
        padding: 0px 10px;
        border-radius: 0px 2px 2px 0px;
        background-color: #fff !important;

        &:hover, &:focus{
            background-color: #fff !important;
            color: $gray-800 !important;
            border: 1px solid #ccc;            
        }
    }
      
    
    //.eh-logged-in-user-navbar{

    //    li{
    //        @include media-breakpoint-down(sm){
    //            display: inline-block;
    //            width: 100%;     
    //            text-align: left;   
    //            float:left;
    //        }            
    //    }     
    //} 
      
}
*/
/* Navigation end  */


.footer-container{
    width:100%;
    position:absolute;
    bottom:0;
    height:210px;   /* Height of the footer 263px*/
    font-size: 0.9em;

    .eh-footer{
        background-color: #131822;

        p{
            color: #fff;
        }

        a, a:hover, a:visited{
            color: $eh-orange-light;
        }

        .container-fluid{
            @include media-breakpoint-up(lg){
                background: url('/assets/images/hex-pattern-orange-fade.png') no-repeat right 30px bottom 8px;
            }
        }

        .row{
            padding: 20px 0 10px 0;
        }

        .eh-copyright {
            p{
                margin-top: 10px;
                font-size: 0.8em;
            }
        
            a, a:hover, a:visited{
                color: #fff;
            }
        }

        .eh-about {
            img {
                float:left;
                padding: 10px;
                background-color: #f2f2f2;
                margin-right: 15px;
            }        
        }

        .eh-footer-menu{
            list-style: none;
            padding-left: 0px;
            margin: 20px 0px;
        
            @include media-breakpoint-up(md){
                padding-left: 0px;
                margin: 20px 0px;
            }
        
            @include media-breakpoint-up(lg){
                padding-left: 40px;
                margin: 0px;
            }
                
            a, a:hover, a:visited{
                color: $eh-orange-light;
            }
        
            li {
                margin-bottom: 5px;
            }
        }
                
        .eh-footer-social-media{
            list-style: none;
            padding-left: 0px;
            
            @include media-breakpoint-up(lg){
                float: right;
            }
        
            @include media-breakpoint-up(md){
                padding-left: 0px;                
            }
        
            li{
                float:left;
                margin-right: 10px;
            }
        
            a{
                color: #0c3554;
                background-color: #fff;
                &:HOVER {
                    background-color: #fff;
                    color: $primary;
                    text-decoration: none;
                }
            }
        }        
    }
}


/* account settings preferences    start */

.eh-preferences-container{
    [aria-expanded="true"] .fa-square-plus, 
    [aria-expanded="false"] .fa-square-minus {
        display: none;
    }
}

/*  account settings preferences    end  */


/* Account public profile     start*/

form.eh-create {
    display: inline;
    margin: 0;
    padding: 0;
    text-align: center;
    button {
        vertical-align: center;
        text-align: center;
    }
}

.eh-public-profile-container{

    .eh-facilities{
        display: block;
        width: 100%;

        p{
            float:left;
            width: 100%;
            
            @include media-breakpoint-up(md){
                width: 50%;
            }

            span {
                min-width: 25px;
            }
        }
    }

    .eh-other-facilities{
        display: block;
        float: left;
        width: 100%;
    }

    .eh-fa-icon-md {
        display: inline-block;
        margin-right:6px;
        min-width: 24px;
        text-align: left;
    }

}
  

/*Account usage summary -------------------------------------------------------------- Start*/
.eh-account-usage-menu .active {
    color: #0C3554;
    font-weight: bold;
}

#piechart{
    @media(max-width: 991px){
        overflow: auto;
        margin-bottom: 20px;
    }
    @media(min-width: 992px){
        overflow: hidden;
    }
}
/*Account usage summary -------------------------------------------------------------- End*/


/*Communities Page ------------------------------------Start*/

.eh-tbl-communities{
    border: 1px solid $gray-500;
} 

/*Communities Page ------------------------------------End*/

/*Maintain community ------------------------------------------------------------ Start*/

.eh-administer {
    .eh-community-admin-report{
        display: block;
        margin: 0 auto;
        white-space: normal;
    }

    .eh-community-profile-image-warning{
        margin: 0 auto;
    }

    .eh-community-profile-image-admin-form{
        display: inline-block;

        .btn{
            @media(max-width: 300px){
                margin-top: 10px;
            }
            @media(min-width: 301px){
                margin-left: 10px;
            }
        }
    }

    .eh-btn-term-delete{
        border: 0px;
        padding: 0px;
        margin-left: 5px;
        color: $danger;

        span{
            padding-bottom: 8px;
        }

        &:hover, &:focus, &:active{
            border: 0px;
            outline: 0px;
            background-color: #fff;
            color: $success;
            box-shadow: none;
        }
    }
}
/*Maintain community ------------------------------------------------------------ End*/

/*Object recycle page ------------------------------------------------------------------- Start*/

.eh-recycle-bin-title-container{

    padding-bottom: 10px;

    .eh-recycle-bin-tabs{
        .nav-tabs{
            .nav-link{
                border-top-left-radius: 0em;
                border-top-right-radius: 0em;
            }
        }
        li a.active{
            padding-bottom: 10px;
            background-color: $light;
        }
    
        h1{
            color:$primary;
            font-size: 1.0em;
            margin-bottom: 0.1rem;
            margin-top: 0.1rem;
        
            @include media-breakpoint-up(md){
                font-size: 1.1em;
                //margin: 10px 0px 5px;
            }
        }
    
        a.active {
            text-decoration: none;
        }
        .active h1{
            color:$dark;
        }
    }

    .eh-object-search-form{
        margin: 0 0 30px 0;
        background-color: $light;
        float: left;
        width: 100%;
        border-bottom: 1px solid $gray-500;
        border-left: 1px solid $gray-500;
        border-right: 1px solid $gray-500;

        .row{
            margin: 10px 0px;
        }
    }

    #sort{

        float: left;
    
        /*Component specific break point*/
        @media (max-width: 350px){
            width: 100%;
            margin: 0px 5px 0px 0px;
        }
        @media (min-width: 351px){
            width: 40%;
            margin: 0px 5px 0px 0px;
        }
    
    }

    #order{

        float: left;
    
        /*Component specific break point*/
        @media (max-width: 350px){
            width: 100%;
            margin: 10px 5px 0px 0px;
        }
        @media (min-width: 351px){
            width: 20%;
            margin: 0px 5px 0px 0px;
        }
    }

    label{
        float: left;
    
        /*Component specific break point*/
        @media (max-width: 440px){
            width: 100%;
            margin-top: 7px;
        }
        @media (min-width: 441px){
            width: 10%;
            margin-top: 7px;
        }
        @media (min-width: 768px){
            margin-right: 10px;
        }
        @media (min-width: 992px){
            margin-right: 0px;
        }
    }
}

.eh-recycle-object-record{
    border: 1px solid $gray-500;
    float:left;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 6px;
    background-color: $light;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    .eh-img-holder{
        float: left;
        width: 81px;
        margin-right: 20px;
    }

    .action-container{
        float: left;
        
        @include media-breakpoint-up(md){
            float: right;
        }
        display: block;

        form{
            display: inline-block;
        }
    }

    .eh-image{
        margin: 0 auto;
        display: block;
    }

    .btn{
        min-width: 100px;
    }
}

/*Object recycle page ------------------------------------------------------------------- End*/

/*Object Record Publish ----------------------------------------------------------- Start*/
.chk-publish-community{
    margin-right: 10px !important;
    margin-left: 1rem;
    display: block;
    float: left;
    margin-top: 15px !important;
}

.publish-community-name{
    margin-left: 10px;
}
/*Object Record Publish ----------------------------------------------------------- End*/


/* Object Record Files ----------------------------------------- Start */

.eh-pdf-upload-details{
    background-color: $light;
    border: 1px solid $gray-500;
}

/* Object Record Files ----------------------------------------- End */


/*API Key ------------------------------------------------ start*/
.eh-api-key-slt-community{
    display: none;
}
/*API Key ------------------------------------------------ end*/

/*Report downloads ------------------------------------------------------------- Start*/
.eh-report-container{
    border: 1px solid $gray-500;
    float:left;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 6px;
    background-color: $light;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    
    .btn{
        min-width: 125px;
        margin-top: 10px;
        margin-left: 5px;
       
        &:hover {
            color:#fff;
        }
    }

    .eh-report-download-status {
        padding-top: 10px;
    }
}




/*Report downloads ------------------------------------------------------------- End*/


/*Sign up verification ------------------------------- start*/

.eh-sign-up-verification, .eh-sign-up-not-activated {
    border: 1px solid $gray-500;
    border-radius: 6px;
    padding: 0px 20px 30px 20px;
    margin: 20px auto;
    max-width: 100%;
    
    @include media-breakpoint-up(md) {
        max-width: 90%;
    }

    @include media-breakpoint-up(lg) {
        max-width: 80%;
    }

    @include media-breakpoint-up(xl) {
        max-width: 75%;
    }
}

/*Sign up verification ------------------------------- end*/


/* Tag Manage Add ------------------------------------start */

.eh-confirm-dialog {
    margin-top: 10px;
    background-color: $light;
    border: 1px solid $gray-500;
    padding: 1rem;
    
    .eh-confirm-cancel {
        margin-right: 20px;
    }
}

/* Tag Manage Add ------------------------------------end */


/* Account detail --------------------------------- start */

.eh-account-profile-image {
    background-color: $light;
    height: 430px;
    border: 1px solid #ddd;   
    text-align: center;
    line-height: 430px;
    float:left;
    display: block;
    width: 100%;
    max-width: 100%;

    img {
        vertical-align: middle;
    }
}


.eh-profile-images-grid {
    height: auto;
    margin: 0 20px 0 0;
    padding: 12px;

    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #eee;

    float: left;
    display: block;
    width: 100%;
    max-width: 100%;

    img {
        float: left;
        margin: 0 6px 0 0;
        cursor: pointer;

        .eh-image-border-active {
            border: 2px solid $success;
            padding: 1px;
        }        
    }
}

.eh-account-explore {

    a, a:hover {
        color: $success;
    }
}


/* Account detail --------------------------------- end */

/* Object Record Image edit ------------------------- start */

.eh-object-record-image-edit{

    .ehImageMedium {
        float: left;
    }

    .ehImageTinySquare {
        float: left;
        margin-left: 20px;
    }

    .eh-rotate-form, .eh-thumbnail-form {
        display: inline;
    }
}


/* Object Record Image edit ------------------------- end */

/* Object Record Video edit ------------------------- start */
    .objectRecordEditVideo {
        img[src=""] {
            display: none;
        }
    }

/* Object Record Video edit ------------------------- end */


/* Object Record Image Upload ------------------ start */
.eh-object-record-image-upload {

    img {
        .eh-image-s {
            padding: 2px;
            border: 1px solid #ddd;
            margin: 0 6px 6px 0;
        }
        .eh-image-ts {
            padding: 2px;
            border: 1px solid #ddd;
        }
    }

    .eh-order-up-form, .eh-order-down-form, .eh-rotate-form, .eh-crop-link, .eh-delete-form {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .listVideoTitle {
        
        max-width: 900px;
        text-wrap: wrap;

        p {
            margin: 0px;

            strong {
                padding-right: 5px;
            }
        }
    }

}

/* Object Record Image Upload ------------------ end */

/* Horizontal Constructor ------------------------------------------- start */

.eh-form-help-block{
    font-size: 0.9em;
    color: $gray-800 !important;
}


/* Horizontal Constructor ------------------------------------------- end */


/* Multi select pick list ----------------------------- start */

.btn-multiselectpicklist-remove{
    margin-top: 5px;
}


/* Multi select pick list ----------------------------- end */


#profileMap{
    margin-top: 0px !important;
}

#map, #profileMap{
    margin-top: 20px;
    border: 1px solid $gray-500;
    margin-bottom: 20px;
    height: 200px;
   
    @include media-breakpoint-up(md){
        margin-bottom: 20px;
        height: 250px;
    }
    @include media-breakpoint-up(lg){
        margin-bottom: 0px;
        height: 350px;
    }
    @include media-breakpoint-up(xl){
        height: 400px;
    }
}

#osm {
    height: 400px;  
}
  

/* Account Object Record report ---------------------------------------------------------- start */

.eh-menu-search-form{
    @media(min-width: 992px){
        margin-left: -30px;
    }
    @media(min-width: 1200px){
        margin-left: 0px;
    }
}


/* Account Object Record report ---------------------------------------------------------- end */

img.eh-image-border {
	border: 2px solid $light;
	padding: 1px;
    display: inline;
    
    &:hover {
        border: 2px solid $primary;
        padding: 1px;
    }
}

.dropdown-item.disabled, dropdown-item:disabled {
	color: $dark;
}

/*
.eh-disabled {
	color: $dark;
}
*/

/* Object Record detail ---------------------------------------------- start */

.panel-confirm-dialog{
    margin-top: 10px;
    border: 1px solid $gray-500;
    background-color: $light;
    padding: 10px;
    display:none;

    .btn{
        min-width: 50px;
    }
}

/* Object Record detail ---------------------------------------------- end */

/*Object category -------------------------------- start*/

.eh-object-record-category{
    float: left;
    display: block;

    background-color: #fff;

    /*Component specific breakpoints*/
    @media(max-width: 350px){
        width: 100%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media(min-width: 351px){
        width: 50%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media(min-width: 510px){
        width: 33.3%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    .eh-object-record-category-container{
        display: block;
        width: 150px;
        max-width: 100%;
    
        -webkit-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
        -moz-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
        box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    
        @media(max-width: 350px){
           margin: 0 auto;
        }
    
        @media(min-width: 351px){
            float: left;
        }
    
        @media(min-width: 510px){
            float: left;
        }

        img{
            display: block;
            float: left;
            max-width: 100%;
            border-radius: 4px 4px 0px 0px;
        }

        .eh-object-record-category-desc-box{
            background-color: $info;
            display:block;
            float: left;
            width: 100%;
            height: 55px;
            border-radius: 0px 0px 4px 4px;

            h3{
                font-size: 1em;
                padding: 0px 10px;
                text-align: center;
                margin-top: 20px;

                .multiline{
                    margin-top: 10px;
                }
            }

            a, a:hover {
                color: #fff;
            }
        }
    }
}

/*Object category -------------------------------- end*/


.ehive-pagination{
    display: block;
    text-align: center;
}

/*Dashboard page ------------------------------------start*/

.eh-dashboard-container{    
    .eh-dashboard-account-detail {
        h1 {
            margin-top: 0px;
            font-size: 2rem;
        }
    
        p{
            margin-bottom: 30px;
        }
    }

    .eh-my-object-tags-footer {
        padding: 10px 15px 20px 15px;
    }

    .eh-index-news-category-desc-box{
        background-color: $primary;
        display:block;
        float: left;
        width: 100%;
        border-radius: 0px 0px 4px 4px;
        overflow: hidden;
        border-bottom: solid 1.25rem $primary;
    
        h3{
            font-size: 1.2em;
            margin-top: 10px;
    
            .multiline{
                margin-top: 10px;
            }
        }
    
        p{
            color: #fff;
            font-size: 0.9em;
    
            a, a:hover {
                //color: $warning-light;
                color: $eh-orange-light;
            }
        }
    
        a, a:hover {
            color: #fff;
        }
    
        @include media-breakpoint-up(md){
            height: 250px;
        }
    
        @include media-breakpoint-up(lg){
            height: 335px;
        }
    
        @include media-breakpoint-up(xl){
            height: 265px;
        }
    }
    
    .eh-index-news-bar{
        width: 100%;
    
        &:hover {
            color: #fff !important;
        }
    }
}

/*Dashboard page -------------------------------------end*/


/*API Keys --------------------------------------------- start*/
.eh-profile-tbl-api-keys table{
    border: 1px solid $gray-500;
}
/*API Keys --------------------------------------------- end*/


/*Public profile page ------------------------------------- start*/
.fa-account-contact-icon, .fa-account-visitor-info-icon{
    min-width: 25px;
}

.facebook-icon{
    margin-right: 5px;
}

.instagram-icon{
    margin-right: 5px;
}

.twitter-icon{
    background-color: #55acee;
    border-radius: 5px;
    margin-right: 5px;
}

/*Public profile page ------------------------------------- end*/

/*Community detail --------------------------------------------------------------- start*/

.eh-community-detail-img{
    float: left;
    display: block;
    margin-right: 20px;
    margin-bottom: 20px;
}

.eh-community-explore, .eh-community-members{
    a, a:hover{
        color: $success;
    }
} 

/*Community detail --------------------------------------------------------------------------- end*/

/*Community Membership Status ----------------------------------------------------------- start*/

.eh-approval-panel {

    .list-group-item{
        display: inline-block;
        width: 100%;

        a{
            display: inline-block;
            padding-top: 9px;
        }
    }
} 

.eh-approval-forms-container{
    float: right;

    form{
        display: inline-block;

        .btn{
            min-width: 100px;
            margin: 5px 0px 5px 5px;
        }
    }
}

/*Community Membership Status --------------------------------------------------------------- end*/

/*Object summary page ---------------------------------------------------------- start*/

.eh-object-summary-pagination-top{
    margin: 0px 0px 20px 0px;
}

.eh-object-summary-pagination-bottom{
    margin: 0px 0px 10px;
}

.eh-object-view-summary{
    border-radius: 4px 0px 0px 4px;

    &:hover {
        color: $white;
    }

}

.eh-object-view-lightbox{
    border-radius: 0px 4px 4px 0px;

    &:hover {
        color: $white;
    }
}

.eh-summary-object-record{
    border: 1px solid $gray-500;
    float:left;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 6px;
    background-color: $light;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    p{
        padding-left: 8px;
    }

    .eh-summary-object-record-img-holder{
        width: 81px;
        float: left;
        margin-right: 10px;
    }

    .eh-image{
        margin: 0 auto;
        display: block;
    }

    .eh-dropdown-option {
        float: right;
    }

    .eh-dropdown-menu span {
        display:inline-block;
    }

    .eh-catalogue-type, .eh-public-private {
        padding-right: 7px;
    }

    .eh-summary-object-record-desc-holder{

        display: block;
        margin-left: 8px;
        
        @include media-breakpoint-up(md){
            display: block;
            float: left;
            max-width: 80%;
        }
        @include media-breakpoint-up(lg){
            display: block;
            float: left;
            max-width: 85%;
        }
        @include media-breakpoint-up(xl){
            display: block;
            float: left;
            max-width: 85%;
        }
    
        p {
            line-height: 1.25em;
        }
    
        span.eh-fa-icon-sm {
            width: 0.95em;
            margin-right: 4px;
        }
    }
}

.summary-dynamic-content{
    display: block;
    //float: left;
}
/*
.eh-object-summary-lightbox{
    display:block;
    float:left;
    background-color: $light;
    border: 1px solid $gray-500;
    margin: 0px 10px 20px 0;
    height: 280px;
    width: 100%;
    padding: 10px;
    border-radius: 6px;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    img{
        margin: 0 auto;
    }

    p{
        overflow: hidden;
        height: 88px;
    }

    .eh-object-summary-lightbox-image{
        display: block;
        height: 160px;
    }

    .eh-object-summary-lightbox-detail{
        display: block;
    
    }

    a {
        display:block;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 10px;
        color: $success;

        &:hover {
            color: $success;
        }
    }
}  */

.refine-results-small-device{
    text-align: center;
    background-color: $primary;
    padding: 7px 10px;
    width: 100%;
    color: #fff;
    font-weight: bold;
    display: block;

    @include media-breakpoint-up(lg){
        display: none;
    }

    &:hover{
        cursor: pointer;
    }
}

.refine-results-large-device{
    display: none;

    @include media-breakpoint-up(lg){
        display: block !important;
    }

    div {
        padding: 7px 0;
        width: 100%;
        font-weight: bold;
        font-size: $h4-font-size;
    }
}
.filters{

    ul {
        list-style: none;
        padding-left: 0;
                
        li {
            float: left;
            margin-bottom: 1rem;
            margin-right: 1rem;
            position: relative;
            
            @media(max-width: 600px){
                width: 100%;
            }

            span.filter-field{
                background-color: $gray-500;
                padding: 0.2rem 0.4rem 0.2rem 0.5rem;
                border-radius: 3px 0px 0px 3px;
                border: 1px solid #DDD;
                display: block;
                float: left;

                @media(max-width: 600px){
                    width: 30%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    
                }
                
            }

            span.filter-label{
                padding: 0.2rem 0.4rem 0.2rem 0.5rem;
                background-color: $light;
                border: 1px solid #DDD;  
                display: block;
                float: left;

                @media(max-width: 600px){
                    width: 63%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    
                }
            }

            span.filter-close{
                padding: 0.2rem 0.5rem 0.2rem 0.5rem;
                background-color: $gray-500;
                border-radius: 0px 3px 3px 0px;
                border: 1px solid #DDD;
                display: block;
                float: left;

                @media(max-width: 600px){
                    
                    width: 7%;
                }
            }  
        }
    }   
}

.facets{

    display: none;
    background-color: #f8f9fa;
    padding: .5rem 1.5rem 1rem 1rem;
    border: 1px solid #ddd;
    margin-bottom: 1rem;


    [aria-expanded="true"] .fa-square-plus, 
    [aria-expanded="false"] .fa-square-minus {
        display: none;
    }

    @include media-breakpoint-up(lg){
        display: block !important;
    }

    .title{
        padding: 0;
        width: 100%;
        color: $black;
        font-weight: bold;
        text-align: left;
        text-decoration: none;
    }

    .content{
        display: inline-block;
        width: 100%;
        padding-left: 0.25rem;

        .fa-check-square-o {
            color: $primary;
        }
    }

    a{
        color: $black;
        &:hover{
            color: $primary;
            text-decoration: none;
        }
    }

    ul{
        margin-bottom: 1rem;
        padding: .5rem 0rem 1rem 0rem;
        list-style: none;
        border-bottom: 1px solid $gray-500;

        li{
            margin-bottom: .4rem;
            padding-left: 1.5rem;
            text-indent: -1.65rem;
        }
    }

    .remove-all-filters{
        text-transform: uppercase;
        display: block;
        color: $primary;
        font-weight: bold;

        &:before{
            content: "[ "
        }

        &:after{
            content: " ]"
        }
    }
    
    .side-facet-more-button{
        background-color: $gray-500; 
    }
   
}

.facet-modal{

    .modal-loader {
        display: block;
        margin: 30px auto !important;
        width: 65px;
    }

    .modal-header {

        background-color: $gray-600;
        //border-bottom: 1px solid $hr-border-color;
        border-bottom: 1px solid $dark;

        h5{
            color: $light
        }
    }

    .facet-more-content{

        ul.facet-more-terms {
            .input-group{

                display: block;
                column-count: 2;

                li{
                    //margin-left: 1.5rem;
                    
                    label {
                        display: inline;
                        margin-left: 1rem;
                    }
                }
            }
        }

        .facet-value{
            &:before{
                content: "("
            }
            &:after{
                content: ")"
            }
        }

        .facet-no-records{
            text-align: center;
            font-weight: bold;
            font-style: italic;
        }

        .deep-facet-pagination{
            ul {
               /* li.page-item.active .page-link {
                    background-color: $gray-600;
                    color: $light; 
                }*/
                li{
                    margin-bottom: 0em;
                    padding-left: 0;
                    text-indent: 0;
                    /*
                    .page-link {
                        background-color: $light;
                        color: $gray-800;
                    } */
                }
            }
            
        }

        .facet-pagination-item{
            cursor: pointer;
        }
    }

    .more-facet-block{
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 2px;
    }
}
   

.lazy-loading-button{
    margin-left: auto !important;
    margin-right: auto !important;
    display: none !important;
    margin-top: 20px;
}

.lazy-loading{
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    display: none;
    margin-left: auto;
    margin-right: auto;
    height: unset !important;
}

/*Object summary page ---------------------------------------------------------- end*/


/***************************** Masonry Start ******************/

.lightbox-sizer {
    float: left;
    
    @media screen and (max-width: 549px){
        width:100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width:48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width:31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width:23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 270px;
        margin: 0 15px 15px 0;
    }
}

.masonry-container .lightbox-item {
    border: 1px solid #ddd;
    -webkit-box-shadow: 3px 3px 5px 0px #f2f3f6;
    -moz-box-shadow: 3px 3px 5px 0px #f2f3f6;
    box-shadow: 3px 3px 5px 0px #f2f3f6;
    text-align: center;
    word-wrap: break-word;
    background-color: #f8f8f8;
    float: left;
    @media screen and (max-width: 549px){
        width: 100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width: 48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width: 31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width: 23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 270px;
        margin: 0 15px 15px 0;
    }

    .lightbox-item a, .lightbox-item span.no-link {
        display: block;
        margin-bottom: 5px;
    }

    .eh-image{
        display: block;
        max-width: 100%;
        margin: 0 auto;
        width: auto !important;
        height: auto !important;
    }

    .lightbox-object-desc {

        border-radius: 0 0 5px 5px;
        padding: 15px;
    
        a, .no-link {
            text-align: left;
            margin-top: 15px;
        }

        .title {
            font-size: 1.1rem;
            font-weight: bold;
        }
    }

    .lightbox-item-no-image{
        background-color: #f9f9f9;
        padding-top: 20px !important;
    }

    .lightbox-desc-box {
        margin-bottom: 0 !important;
        display: inline-block;
        width: 100%;
    
        &:hover{
            text-decoration: none;
        }
        span{
            display: block;
            width: 100%;
            height: 130px;
            text-align: left;
            color: $black;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00aeef+0,aed136+100 */
            background: rgb(221,221,221); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(45,87,120,0.5) 0%, rgba(221, 221, 221, 0.5) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(45,87,120,0.5) 0%, rgba(221,221,221,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg,  rgba(45,87,120,0.5) 0%, rgba(221,221,221,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(45,87,120,0.5)', endColorstr='rgba(221,221,221,0.5)',GradientType=1 ); /* IE6-9 */
            opacity:0.9;
            padding: 0.2rem 1rem 0.5rem 1rem;

            overflow: hidden;
            line-height: 30px;
            max-height: 130px;

            &::after{
                content:'';
                width:100%;
                height:130px;    
                position:absolute;
                left:0;
                top:0;
                background: linear-gradient(transparent, rgba(221,221,221,1) 117% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                background: -moz-linear-gradient(transparent , rgba(221,221,221,1) 117% ); /* FF3.6-15 */
                background: -webkit-linear-gradient(transparent , rgba(221,221,221,1) 117% ); /* Chrome10-25,Safari5.1-6 */
            }
        
        }
    }

    .lightbox-object-type-icon {
        position: absolute;
        height: 0;
        width: 0;
        bottom: 0;
        right: 0;
        border-left: 90px solid transparent;
        border-bottom: 50px solid $gray-500;

        svg, span{
            position: absolute;
            right: 4px;
            bottom: -22px;
            //color: $success-light;
            color: #719BBB;
        }
        
        .cat-type{
            position: absolute;
            right: 25px;
            bottom: -22px;
            //color: $success-light;
            color: #719BBB;
        }
    }

    .eh-dropdown-menu {
        a {
            margin-top: 0;
        }
    }
    
}


/***************************** Masonry End ********************/


.eh-search-tabs {
    h1 {
        font-size: 1.1em;
    }
}


/*Object search page --------------------------------------------------------------start*/

.eh-object-search-form{

	margin: -10px -15px 10px -15px;

    float: left;
    width: 100%;

    .row{
        margin: 10px 0px;
    }
}

.eh-search-image-grid img{
     float:left;
     margin: 0px 10px 10px 0px;
 }


.eh-search-menu{

    .active{
        //color: $info;
        font-weight: bold;
    }

    .separator{
        border-top: 2px solid $gray-500 !important;
    }
}

/*Object search page -----------------------------------------------------------------End*/

/*Object detail page ----------------------------------------------------------------- Start*/
.cc-image{
    margin-top: 10px;
    display: block;
}

.cc-desc{
    margin-top: 10px;
    display: block;
}

.btn-create-similar-object, .btn-move-to-recycle, .btn-change-cat-type {
    margin: 0px;
    padding: 0px;
}

/*
.recycle-model{
    .modal-header{
        background-color: $gray-600;
        //border-bottom: 1px solid $hr-border-color;
        border-bottom: 1px solid $dark;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        
        h5{
            color: $light
        }
    }
}
*/

.eh-object-detail-image-container{
    float: left;
    display: block;
    width: 100%;
    margin-bottom: 0;
    max-width: 100%;
}

#openseadragon-toolbar {
    height: auto;

    padding: 12px;
    //margin-top: 4px;
    //margin-bottom: 6px;

    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #eee;

}

#openseadragon1 {
    width: auto;
    background-color: #F8F8F8;
    height: 430px;
    border: 1px solid #ddd;
}

.eh-object-high-res-images-grid {

    height: auto;
    margin: 0 20px 20px 0;
    padding: 12px;

    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #eee;

    float: left;
    display: block;
    width: 100%;

    /*
    @media(max-width: $breakpoint-xs){
        max-width: 100%;
    }
    @media(min-width: $breakpoint-sm){
        max-width: 100%;
    }
    @media(min-width: $breakpoint-md){
        max-width: 555px;
        margin-right: 20px;
    }
    */

    img {
        float: left;
        margin: 0px 2px 2px 0px;
        cursor: pointer;

        .eh-image-border-active {
            border: 2px solid $success;
            padding: 1px;
        }
    }
}



.eh-object-low-res-image {
    background-color: #F8F8F8;
    height: 430px;
    border: 1px solid #ddd;   
    text-align: center;
    line-height: 430px;
    float:left;
    display: block;
    width: 100%;

    /*
    @media(max-width: $breakpoint-xs){
        max-width: 100%;
    }
    @media(min-width: $breakpoint-sm){
        max-width: 100%;
    }
    @media(min-width: $breakpoint-md){
        max-width: 555px;
        margin-right: 20px;
    }
    */

    img {
        vertical-align: middle;
    }
}


.eh-object-low-res-images-grid {
    height: auto;
    padding: 12px;

    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #eee;

    float: left;
    display: block;
    width: 100%;

    /*
    @media(max-width: $breakpoint-xs){
        max-width: 100%;
    }
    @media(min-width: $breakpoint-sm){
        max-width: 100%;
    }
    @media(min-width: $breakpoint-md){
        max-width: 555px;
        margin-right: 20px;
    }
    */

    img {
        float: left;
        margin: 0 6px 0 0;
        cursor: pointer;

        .eh-image-border-active {
            border: 2px solid $success;
            padding: 1px;
        }
    }
}

.eh-original-image-links {
	float: left;
	display:block;
	width: 100%;
	padding-bottom: 6px;
	border-bottom: 1px solid #ddd;
	margin-top: 6px;
    margin-bottom: 10px;
    
    a {
        padding-left: 6px;
    }
}
 
.eh-original-image-single-link {
    text-align: center;
    background-color: #eee;
    padding-bottom: 6px;
    padding-top: 6px;
}

.eh-original-audio-single-link {
    text-align: center;
    background-color: #F8F8F8;
    padding-bottom: 6px;
}

.eh-comments-container{
    display: block;
    float: left;
    width: 100%;
    margin-top: 20px;
}

.eh-comment{
    border-top: 1px solid $gray-500;
    padding: 5px 0px;

    .commentor-name{
        font-weight: bold;
    }

    .when-created{
        float: right;
        color: $dark;
        font-size: 0.9em;
    }

    .comment-delete{
        float: right;
        font-size: 0.9em;
        margin-left: 20px;
    }
}

.eh-add-comments{
    margin: 20px 0px;
    float: left;
    width: 100%;
}

.eh-delete-comment-form, .eh-reject-comment-form{
    float: right;
    display: inline-block;
}

#addCommentsCollapseAnchor:hover, #addCommentsCollapseAnchor:focus{
    text-decoration: none;
}

div.eh-comment-object-simple-summary {
    border-top: 1px solid #dee2e6;
    font-size:80%;
}

.eh-tag-form{
    margin-bottom: 10px;
}

.eh-btn-tag-delete, .eh-btn-comment-delete{
    border: 0px;
    padding: 0px;
    margin-left: 5px;
    color: $danger;

    span{
        padding-bottom: 8px;
    }
}

.eh-btn-tag-delete:hover, .eh-btn-tag-delete:focus, .eh-btn-tag-delete:active, .eh-btn-tag-delete:active:focus,
.eh-btn-comment-delete:hover, .eh-btn-comment-delete:focus, .eh-btn-comment-delete:active, .eh-btn-comment-delete:active:focus {
    border: 0px;
    outline: 0px;
    background-color: #fff;
    color: $danger;
    box-shadow: none;
    text-decoration: underline;
}

.eh-delete-tag-dropdown-item {
    color: $danger;
    &:hover{
        color: $danger;
        text-decoration: underline;
    }
}

/*
.eh-dropdown-option-button {
    border: 0px;
    padding: 0px;
    margin-left: 18px;
    margin-top: 8px;
    color: $danger;
	font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 16px;
    font-weight: normal; 
    
    &:hover, &:focus, &:active, &:active:focus {
        border: 0px;
        outline: 0px;
        background-color: #f5f5f5;
        color: $success;
        box-shadow: none;
    }

    span {
        padding-bottom: 8px;
        padding-right: 5px;
    }
}
*/

/*Object detail page ----------------------------------------------------------------- End*/

/* Object Record Original Image -------------------------- start */

.ehDownloadImage {
    padding: 2px;
    border: 1px solid #ddd;
    margin: 0 0 10px 0;
    
}
/* Object Record original Image ----------------------------end */


/*Object draft page ------------------------------------------------------------------- Start*/

.eh-draft-object-sort-form{
    margin-top: 10px;
}

.eh-draft-records-pagination-top{
    margin: 0px 0px 20px 0px;
}

.eh-draft-records-pagination-bottom{
    margin: 0px 0px 10px;
}

.eh-draft-object-record{
    border: 1px solid $gray-500;
    float:left;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 6px;
    background-color: $light;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    .eh-draft-object-record-desc-holder{
        @media(max-width: 500px){
            width: 100%;
            margin-top: 20px;
        }
        @media(min-width: 501px){
            width: 80%;
        }
        @media(min-width: 768px){
            width: 85%;
        }
        @media(min-width: 992px){
            width: 88%;
        }
        @media(min-width: 1200px){
            width: 90%;
        }
    }

    .eh-draft-object-record-img-holder{
        float: left;
        padding-right: 20px;
        @media(max-width: 500px){
            width: 100%;
        }
        @media(min-width: 501px){
            width: 20%;
        }
        @media(min-width: 768px){
            width: 15%;
        }
        @media(min-width: 992px){
            width: 12%;
        }
        @media(min-width: 1200px){
            width: 10%;
        }
    }
/*
    .eh-image{
        margin: 0 auto;
        display: block;
    } */

    .btn{
        min-width: 100px;
    } 

    .eh-dropdown-option {
        float: right;
    }

    .dropdown form {
        padding-left: 8px;
    }
/*
    .eh-catalogue-type{
        padding-right: 8px;
    }  */
}

/*Object draft page ------------------------------------------------------------------- End*/


/* Acquisition summary page ------------------------------------------------------------------- Start*/
.eh-acq-records-pagination-top{
    margin: 0px 0px 20px 0px;
}

.eh-acq-records-pagination-bottom{
    margin: 0px 0px 10px;
}

.eh-acq-record{
    border: 1px solid $gray-500;
    float:left;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 6px;
    background-color: $light;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    .eh-catalogue-type{
        float:right;
    }
}

 
/* Acquisition summary page ------------------------------------------------------------------- End*/

/*Account usage history -------------------------------------------------------------- Start*/
#curve_chart{
    @media(max-width: 991px){
        overflow: auto;
        margin-bottom: 20px;
        height: 400px;
    }
    @media(min-width: 992px){
        overflow: hidden;
        width: 850px;
        height: 500px;
    }
}
/*Account usage history -------------------------------------------------------------- End*/

/*Account profile location ------------------------------------------------------------- Start*/
#eh-geocode-error-container{
    display:none;
}

#accountLocationMap{
    border: 1px solid $gray-500;
    margin-top: -40px;
    height: 200px;
    
    @include media-breakpoint-up(md){
        margin-top: -40px;
        height: 250px;
    }
    @include media-breakpoint-up(lg){
        margin-bottom: 0px;
        margin-top: 80px;
        height: 350px;
    }
    @include media-breakpoint-up(xl){
        margin-top: 40px;
        height: 400px;
    }
}

#accountLocationForm .btn{
    @media(max-width: 350px){
        margin-top: 10px;
    }
}
/*Account profile location ------------------------------------------------------------- End*/

/* Account profile details ----------------------------------------------------------- Start */

.eh-public-details-container{
    .form-control, .smart-element {
        font-size: 1rem !important;
        color: $eh-dark;
        font-family: inherit;
    }
}
/* Account profile details ----------------------------------------------------------- End */
/*Report downloads ------------------------------------------------------------- Start*/

.eh-report-container{
    border: 1px solid $gray-500;
    float:left;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 6px;
    background-color: $light;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    h2{
        margin-top: 0px;
    }

    .btn{
        min-width: 130px;
        margin-top: 10px;
        
        @include media-breakpoint-up(md){
            margin-top: 10px;
        }
        @include media-breakpoint-up(lg){
            margin-top: 0px;
        }

        &:hover{
            color:#fff;
        }
    }
}

.eh-report-download-status {
	padding-top: 10px;
}


/*Report downloads ------------------------------------------------------------- End*/


/*Community join membership ------------------------------------------------------ Start*/

.eh-join-membership-static-label{
    
    float: left;
    
    @include media-breakpoint-up(md){
        float: right;
    }
}

/*Community join membership ------------------------------------------------------ End*/

/*Community leave membership ------------------------------------------------------ Start*/

.eh-leave-membership-static-label{
    
    float: left;
    
    @include media-breakpoint-up(md){
        float: right;
    }
}

/*Community leave membership ------------------------------------------------------ End*/


/*Tag Cloud start*/
.eh-tagCloud a{
    padding: 0px 5px;
}

.eh-tagCloud .more, .eh-tagCloud .less{
    margin: 20px 0px;
    display: block;
    font-weight: bold;
}

a.ehTag-1 {
    font-size: 0.7em;
    color: #8f412f;
}

a.ehTag-2 {
    font-size: 0.9em;
    color: #005051;
}

a.ehTag-3 {
    font-size: 1.1em;
    line-height: 1.5;
    color: #854018;
}

a.ehTag-4 {
    font-size: 1.3em;
    line-height: 1.4;
}

a.ehTag-5 {
    font-size: 1.5em;
    line-height: 1.3;
    color: #b50000;
}

a.ehTag-6 {
    font-size: 1.7em;
    line-height: 1.3;
    color: #8b008b;
}

a.ehTag-7 {
    font-size: 1.9em;
    line-height: 1.2;
    color: #006600;
}

a.ehTag-8 {
    font-size: 2.1em;
    line-height: 1.2;
    color: #58007e;
}

a.ehTag-9 {
    font-size: 2.3em;
    line-height: 1.1;
    color: #00613a;
}

a.ehTag-10 {
    font-size: 2.5em;
    line-height: 1.1;
    color: #575757;
}
/*Tag Cloud end*/


/*********************************************************** Can be deleted ********************************************/


/*EHive search all --------------------------- start*/
h2.eh-summary-all-title{
    margin-bottom: 15px;
}

.eh-summary-all-title a, .eh-summary-all-title a:hover{
    color: $success;
}

/*EHive search all --------------------------- end*/

/* Popular and recent records --------------------------------------------- start */

.eh-pager{
    margin: 20px 0px;
    padding: 0px;
    list-style: none;
    text-align: center;

    li{
        display: inline;

        a:hover{
            color: #fff !important;
        }
        
    }
}

/* Popular and recent records --------------------------------------------- end */


/*Explore -------------------------------------- start*/

.eh-explore-image-grid img{
    float:left;
    margin: 0px 2px 2px 0px;
}

.eh-explore-menu .active, .eh-search-menu .active{
    //color: $info;
    font-weight: bold;
}

.eh-search-menu .separator{
    border-top: 2px solid $gray-500 !important;
}

.eh-explore-collections{
    margin-bottom: 20px;

    .eh-explore-collections-grid{
        margin: 20px 0px;

        .row{
            margin: 20px 0px 0px 0px;
            padding: 12px 0px;
            border: 1px solid $gray-500;
            border-radius: 6px;
        
            -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
            -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
            box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
        }

        .details{
            margin-bottom: 10px;
        }

        a, a:hover {
            color: $success;
        }

        h2{
            font-size: 1.1em;
            margin-top: 0px;
            margin-bottom: 10px;
        }

        img{
            margin-right: 5px;
        }
    }

    .eh-explore-collections-row-fill{
        background-color: $light;
    }
}


.eh-explore-home-page-category{

    display: block;
    float: left;
    background-color: #fff;

    /*Component specific breakpoints*/
    @media(max-width: 350px){
        width: 100%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media(min-width: 351px){
        width: 50%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media(min-width: 510px){
        width: 33.3%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @include media-breakpoint-up(lg){
        width: 16.66%;
        margin-right: 0px;
        margin-bottom: 25px;
    }
}

.eh-explore-home-page-category-container{
    display: block;
    max-width: 100%;
    width: 150px;

    -webkit-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);

    @media(max-width: 350px){
       margin: 0 auto;
    }

    @media(min-width: 351px){
        float: left;
    }

    @media(min-width: 510px){
        float: left;
    }

    @include media-breakpoint-up(lg){
       float: left;
    }

    img{
        display: block;
        float: left;
        max-width: 100%;
        border-radius: 4px 4px 0px 0px;
    }
}

.eh-explore-home-page-category-desc-box{
    background-color: $info;
    display:block;
    float: left;
    width: 100%;
    border-radius: 0px 0px 4px 4px;

    @media(min-width: 351px){
        height: 55px;
    }

    h3{
        font-size: 1em;
        padding: 0px 10px;
        text-align: center;
        margin-top: 20px;

        .multiline{
            margin-top: 10px;
        }
    }

    a, a:hover {
        color: #fff;
    }
}

.eh-explore-multi-category{
    float: left;
    display: block;

    background-color: #fff;

    width: 100%;
    margin-bottom: 25px;
    
    @include media-breakpoint-up(md){
        width: 33.33%;
        margin-right: 0px;
        margin-bottom: 25px;
        padding-right: 2%;
    }
}

.eh-explore-multi-category-container{
    display: block;
    max-width: 100%;

    -webkit-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);

    margin: 0 auto;
    
    @include media-breakpoint-up(md){
        float: left;
    }

    img{
        display: block;
        max-width: 100%;
        margin: 5px auto;
    }
}

.eh-explore-multi-category-desc-box{
    background-color: $info;
    display:block;
    float: left;
    width: 100%;
    border-radius: 0px 0px 4px 4px;

    @include media-breakpoint-up(md){
        height: 75px;
    }

    @include media-breakpoint-up(lg){
        height: 55px;
    }

    @include media-breakpoint-up(xl){
        height: 55px;
    }

    h3{
        font-size: 1.2em;
        padding: 0px 10px;
        margin-top: 15px;
        text-align: center;

        .multiline{
            margin-top: 10px;
        }
    }

    p{
        padding: 0px 10px;
        color: #fff;
        font-size: 0.9em;

        a, a:hover{
            color: $primary;
        }
    }

    a, a:hover{
        color: #fff;
    }
}

.eh-explore-multi-category-img-container{
    display: block;
    border: 1px solid $gray-500;
    float: left;
    border-radius: 4px 4px 0 0;
    padding: 10px;
    background-color: $light;
    width: 100%;
}

.eh-explore-multi-category-img{
    display: block;
    float: left;

    @media(max-width: 220px){
        width: 100%;
    }

    @media(min-width: 201px){
        width: 50%;
    }

    @media(min-width: 301px){
        width: 33.3%;
    }

    @media(min-width: 550px){
        width: 16.6%;
    }

    @media(min-width: 767px){
        width: 16.6%;
    }

    @include media-breakpoint-up(md){
        width: 50%;
    }

    @include media-breakpoint-up(lg){
       width: 33.3%;
    }

    @include media-breakpoint-up(xl){
       width: 33.3%;
    }
}

.eh-explore-tagCloud{
    border: 1px solid $gray-500;
    padding: 10px;
    border-radius: 4px;

    -webkit-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
}

h3.eh-explore-home-page-category-account-desc{
    font-size: 0.9em;
    padding: 0px 10px 10px;
    text-align: center;
    margin-top: 10px;
}
/*Explore -------------------------------------- end*/


/*Accounts search ----------------------------- start*/
.eh-account-search-form{
    margin: -10px -15px;
    width: 100%;

    .row{
        margin: 10px 0px;
    }
}

.eh-featured-account-record{
    float: left;
    display: block;
    background-color: #fff;

    /*Component specific breakpoints*/
    @media(max-width: 350px){
        width: 100%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media(min-width: 351px){
        width: 50%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media(min-width: 510px){
        width: 33.3%;
        margin-right: 0px;
        margin-bottom: 25px;
    }
}

.eh-featured-account-record-container{
    display: block;
    width: 150px;
    max-width: 100%;

    -webkit-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);

    @media(max-width: 350px){
       margin: 0 auto;
    }

    @media(min-width: 351px){
        float: left;
    }

    @media(min-width: 510px){
        float: left;
    }

    img{
        display: block;
        margin: 0 auto;
        max-width: 100%;
        vertical-align: middle;
    }
}

.eh-featured-account-record-desc-box{
    background-color: $info;
    display:block;
    float: left;
    width: 100%;
    height: 65px;
    border-radius: 0px 0px 4px 4px;
    overflow: hidden;

    h3{
        font-size: 0.9em;
        padding: 0px 10px 10px;
        text-align: center;
        margin-top: 10px;

        .multiline{
            margin-top: 10px;
        }
    }

    a, a:hover {
        color: #fff;
    }
}

.eh-featured-account-record-img-box{
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    /*height: 162px;*/
    width: inherit;
    background-color: #fff;
    /*border: 1px solid $gray-light;*/
    /*padding: 5px;*/
    /*border-radius: 4px 4px 0px 0px;*/

    span{
        vertical-align: middle;
    }
}

/*Accounts search ----------------------------- end*/


/*Accounts summary --------------------------- start*/
.eh-account-summary-pagination-top{
    margin: 0px 0px 20px 0px;
}

.eh-account-summary-pagination-bottom{
    margin: 0px 0px 10px;
}

.eh-account-summary-search-form {
    input, select, button{
        margin-top: 10px;
    }
}

.eh-summary-account-record{
    border: 1px solid $gray-500;
    float:left;
    width: 100%;
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 6px;
    background-color: $light;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    p{
    
        max-width: 100%;
        
        @include media-breakpoint-up(md){
            max-width: 100%;
        }
        @include media-breakpoint-up(lg){
            float: left;
            max-width: 90%;
        }
        @include media-breakpoint-up(xl){
            max-width: 85%;
        }
    }

    h2{
        margin-top: 0px;
        margin-bottom: 15px;
    }

    a, a:hover {
        color: $success;
    }

    .eh-image{
        margin: 0 auto;
        display: block;
    }

    .eh-summary-account-record-img-holder{
        width: 81px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    span{
        display:block;
        margin-top: 5px;
    }
    
    .eh-catalogue-type{
        float:right;
    }
}

/*Accounts summary --------------------------- end*/


/*Communities summary ------------------------------------------------------------ start*/
.eh-communities-summary-pagination-top{
    margin: 0px 0px 20px 0px;
}

.eh-communities-summary-pagination-bottom{
    margin: 0px 0px 10px;
}

.eh-summary-communities-record{
    border: 1px solid $gray-500;
    display: block;
    padding: 10px 15px 25px;
    float:left;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 6px;
    background-color: $light;

    -webkit-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);
    box-shadow: 3px 3px 5px 0px rgba(242,243,246,1);

    p{
        max-width: 100%;
        
        @include media-breakpoint-up(md){
            max-width: 100%;
        }
    
        @include media-breakpoint-up(lg){
            float: left;
            max-width: 90%;
        }
    
       @include media-breakpoint-up(xl){
            float: left;
            max-width: 85%;
        }
    }

    .eh-image{
        float: left;
        display: block;
    }

    h2{
        margin-top: 0px;
        margin-bottom: 15px;
    }

    //a, a:hover {
    //    color: $success;
    //}

    .eh-summary-communities-record-img-holder{
        width: 81px;
        height: 81px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 4px;
    }

    span{
        display:block;
        margin-top: 5px;
    }

    .eh-catalogue-type{
        float:right;
    }

    h5{
        padding-top: 3px;
    }
}

/*Communities summary ------------------------------------------------------------ end*/


/*Communities search ------------------------------------------------------------ start*/
.eh-communities-search-form{
    margin: -10px -15px;
    float: left;
    width: 100%;

    .row{
        margin: 10px 0px;
    }
}

.eh-featured-community-record{
    float: left;
    display: block;
    background-color: #fff;

    /*Component specific breakpoints*/
    @media(max-width: 350px){
        width: 100%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media(min-width: 351px){
        width: 50%;
        margin-right: 0px;
        margin-bottom: 25px;
    }

    @media(min-width: 510px){
        width: 33.3%;
        margin-right: 0px;
        margin-bottom: 25px;
    }
}

.eh-featured-community-record-container{
    display: block;
    width: 150px;
    max-width: 100%;

    -webkit-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    -moz-box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);
    box-shadow: 5px 5px 5px 0px rgba(242,243,246,1);

    @media(max-width: 350px){
       margin: 0 auto;
    }

    @media(min-width: 351px){
        float: left;
    }

    @media(min-width: 510px){
        float: left;
    }

    img{
        display: block;
        float: left;
        max-width: 100%;
        border-radius: 4px 4px 0px 0px;
    }
}

.eh-featured-community-record-desc-box{
    background-color: $info;
    display:block;
    float: left;
    width: 100%;
    height: 55px;
    border-radius: 0px 0px 4px 4px;

    h3{
        font-size: 1em;
        padding: 0px 10px;
        text-align: center;
        margin-top: 20px;
    }

    h3.multiline{
        margin-top: 10px;
    }

    a, a:hover {
        color: #fff;
    }
}

.eh-subscription-options {
    .card-header {
        color: #fff;
        background-color: #23435c;
    }
    .card-body {
        background-color: #f4f4f4;
    }
}

/*Communities search ------------------------------------------------------------ end*/

/* Create and edit screens   ------------------------------------------------ start */

.eh-gwt-panel {
    .card-body {
        padding: 0;
    }
}

/* Create and edit screens   ------------------------------------------------ end */

.x-form-field-wrap .x-form-trigger {
    right: 0;
}

.eh-edit-legend {
    border-style: none;
    border-width: 0;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    width: auto;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
}
.eh-edit-fieldset {
    border: 1px solid #e0e0e0;
    padding: 10px;
}

div.eh-ng-select > ng-select {
    width:90% !important;
}

/* Users page ------------------------------------------------ start */
#eh-users {
    tr.active-user-row {
        cursor:pointer;
    }
}


.eh-users-tbl {

    padding: 0 1.5rem 0.5rem 1.25rem;

    table{
        border: 1px solid $gray-500;
        
    }
}

.eh-users-panel{
    
    [aria-expanded="true"] .fa-square-plus, 
    [aria-expanded="false"] .fa-square-minus {
        display: none;
    }

    table{
        th{
            width: 25%;
        }
    }

    .title{
        //padding: 2rem;
        margin: 0.5rem 1.5rem 0.5rem 1.5rem;
        //width: 100%;
        color: $black;
        background-color: $gray-500;
        font-weight: bold;
        text-align: left;
    }

    .content{
        display: inline-block;
        width: 100%;
        padding-left: 0.25rem;

        .fa-check-square-o {
            color: $primary;
        }
    }
    
}

/* Users page ------------------------------------------------ end */

/* Enterprise product contact sales page -------------- start */

.eh-enterprise-contact-container {
    .otherRequiredStorage{
        display: none;
    }

    .otherRequiredUsers{
        display: none;
    }

    .otherRequiredRecords{
        display: none;
    }
}

/*Enterprise product contact sales page -------------------- end */


/* Page view statistics --------------------------- start */

.eh-page-view-statistics {

    .eh-col-width{
        @media(min-width: 1600px){
            max-width: 66.66667%;
        }
    }
    
    .form-inline{
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        color: #495057;
        width:100%;

        @media (min-width: 535px){
            width: 75%;
        }

        @include media-breakpoint-up(md){
            width: 9rem;
        }
        
    }

    .load{
        width: 100%;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        display: none;
        margin-left: auto;
        margin-right: auto;        
    }
    
}

/* Page view statistics --------------------------- end */


.eh-comments-container{

    .eh-flex-comment, .eh-flex-object {
        
        width: 100%;

        @include media-breakpoint-up(md){
            width: 30%;
        }
    }

    .eh-flex-comment-details {

        width:100%;

        @include media-breakpoint-up(md){
            width: 25%;
        }
    }

    .eh-flex-action {
        
        width: 100%;

        @include media-breakpoint-up(md){
            width: 15%;
        } 
    }
    
}

.eh-advanced-search-container{

    .add-btn{
        width: 60%;

        @include media-breakpoint-up(sm){
            width: 35%;
        }

        @include media-breakpoint-up(md){
            width: 25%;
        }

        @include media-breakpoint-up(lg){
            width: 20%;
        }

        @include media-breakpoint-up(xxl){
            width: 15%;
        }

        @media (min-width: 1750px){
            width: 12%;
        }
    }

    .eh-label-width{
        width: 100%;

        @include media-breakpoint-up(md){
            width: 25%;
        }

        @include media-breakpoint-up(lg){
            width: 25%;
        }

        @include media-breakpoint-up(xl){
            width: 16.66667%;
        }
    }

    .eh-field-value-width{

        width: 100%;

        @include media-breakpoint-up(lg){
            width: 50%;
        }
    }

    .eh-field-value-full-width{
        width: 100%;
    }

    .eh-field-value2{
        display: none;
    }

    #searchFieldValues_0_remove_button{
        display: none;
    }

    #searchFieldValues_0_fieldOperator{
        display: none;
    }

    #searchFieldValues_0_side_terms_more_button{
        display: none;
    }

    label[for=rights]{
        text-transform: capitalize;  
        text-align: start;      
    }
/*
    label[for=catalogueType]{
        text-transform: capitalize;  
        text-align: start;      
    }
*/
    [aria-expanded="true"] .fa-square-plus, 
    [aria-expanded="false"] .fa-square-minus {
        display: none;
    }
    
    #rights, #catalogueType{
        label{

            display: inline;
            margin-left: 0.5rem;

            &::after{
                content: "\A";
                white-space: pre-line;
            }
        }

        input{
            margin-left: -1.5rem;
        }
    }

    .terms-modal{

        .modal-loader {
            display: block;
            margin: 30px auto !important;
            width: 65px;
        }
    
        .terms-more-content{
            
            ul{
                margin-bottom: 1rem;
                padding: .5rem 0rem 1rem 0rem;
                list-style: none;
                border-bottom: 1px solid $gray-500;
        
                li{
                    margin-bottom: .4rem;
                    padding-left: 1.5rem;
                    text-indent: -1.65rem;
                }
            }

            ul.terms-more-terms {
                .input-group{
    
                    display: block;
                    column-count: 2;
                    list-style: none;
    
                    li{
                        //margin-left: 1.5rem;
                        
                        label {
                            display: inline;
                            margin-left: 1rem;
                        }
                    }
                }
            }
    
            .terms-value{
                &:before{
                    content: "("
                }
                &:after{
                    content: ")"
                }
            }
    
            .no-terms{
                text-align: center;
                font-weight: bold;
                font-style: italic;
            }
            
            
            .deep-terms-pagination{
                ul {
                   
                    li{
                        margin-bottom: 0em;
                        padding-left: 0;
                        text-indent: 0;
                        
                    }
                }
                
            }
    
            .terms-pagination-item{
                cursor: pointer;
            } 
        }
    
        .more-terms-block{
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 2px;
        }
    }

    

    ul.ul-selected-values {
        display: none;
        list-style: none;
        padding-left: 0.5rem;
                
        li {
            float: left;
            margin-bottom: 1rem;
            margin-right: 1rem;
            position: relative;
            
            @media(max-width: 600px){
                width: 100%;
            }

            span.filter-field-value{
                background-color: $gray-500;
                padding: 0.2rem 0.4rem 0.2rem 0.5rem;
                border-radius: 3px 0px 0px 3px;
                border: 1px solid #DDD;
                display: block;
                float: left;

                @media(max-width: 600px){
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    
                }
                
            }

            button.filter-close{
                padding: 0.2rem 0.5rem 0.2rem 0.5rem;
                background-color: $gray-500;
                border-radius: 0px 3px 3px 0px;
                border: 1px solid #DDD;
                display: block;
                float: left;

                @media(max-width: 600px){
                    
                    width: 7%;
                }
            }  
        }
    }   
    
}

/* Social media share tag -------------------------------------------------------------------- start */
.eh-social-media-share-tag{
    .eh-link{
        padding: 0.75rem 0.75rem;
    }
}

/* Social media share tag -------------------------------------------------------------------- end */


/* Settings drop-down field customisation ---------------------------------------------------- start */
.custom-select {
    /* Add custom styles for the select field here */
    width: auto;
    max-width: 100%; 
}
/* Settings drop-down field customisation ----------------------------------------------------- end */

.noRegionState{
    display: none;
}

.dropDownPref {

    align-items: center;

    label{
        width:auto;
    }

    @media (min-width: 1120px){
        .col-auto{
            min-width: 500px;
        }  
    }

    @media (max-width: 625px){
        .col-auto{
           width: 100%;
        } 
        padding-bottom:10px;
    }

    .col-auto{
        text-wrap: nowrap;
        max-width: 280px;
    }
}

.searchHelp{

    .svg-inline--fa{
        padding: 5px;
        height: 1.7em;
        margin-left: 5px;
    }
}

#relevantOrderText{
    padding: .375rem 2.25rem .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    margin: 0px 5px 0px 0px;
    background-color: #e9ecef;
    color: #131822;
    text-wrap: nowrap;
    min-width: 125px;

}

.sortCustom{
    display: none;
}

.recBtn{
    vertical-align: bottom !important;
}

/* Video and Audio Details display ----------------------------------------------------------- start */

.audio-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .noFirstAudio{
        text-align: center;
        font-style: italic;
        color: $eh-orange;
        margin-top: 10px;
    }

    .audio-player {
        padding: 20px;
        text-align: center;
        margin: 0;
        background-color: #F8F8F8;

        .audioTitle {
            font-size: 22px;
        }

        audio::-webkit-media-controls-panel {
            background-color: #ccc; 
          }
    }

    .audio-playlist {
        text-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
        padding: 15px 20px;
        gap: 10px;
        justify-content: center;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        background-color: #F8F8F8;

        .audio-playlist-item {
            border: 1px solid #ddd;
            border-radius: 5px;
            display: flex;
            flex-wrap: nowrap;
            background-color: #eee;


            .audioPlayIcon, .apiTitle, .musicIcon {
                padding:5px;
            }

            &:hover {
                color:  $eh-orange;
                border: 1px solid $eh-orange;
            }

            .audioPlayIcon {
                margin-right: 10px;
            }

            .apiTitle {
                margin-right: auto;
                white-space: nowrap;         /* Prevent the text from wrapping */
                overflow: hidden;            /* Hide any text that overflows the container */
                text-overflow: ellipsis;     /* Display ellipses (...) for overflowed text */
            }

            .musicIcon {
                align-items: right;
            }
        } 
    }

    .audio-playlist  > * {
        margin: 10px 0;
    }
}

.video-container {

    .videoDisplay {
        text-align: center;
        background-color: #F8F8F8;

        #videoTitle {
            padding: 20px;
        }
    }

    .noFirstVideo {
        text-align: center;
        font-style: italic;
        color: $eh-orange;
        margin-top: 10px;
    }

    .video-player {
        width: 70%;
        border-radius: 5px;
        background-color: black;
        margin-bottom: 20px;
        text-align: center;
        max-height: 450px;
        max-width: 700px;
    }

    .video-playlist {
        display: flex;
        overflow-x: auto;
        width: 100%;
        padding: 15px 15px;
        gap: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        min-height: 160px;
        background-color: #eee;

        .video-playlist-item {
            flex-shrink: 0;
            width: 145px;
            cursor: pointer;
            transition: transform 0.3s ease;

            .vpiTitle {
                text-align: center;
                padding-top: 5px;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
        
        .video-playlist-item img:hover {
            border: 2px solid $eh-orange;
        }
        
        .video-playlist-item img {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 5px;
        }
    }

    .video-playlist  > * {
        margin: 0px 20px;
    }
}

//Display of thumbnails for selection
.thumbnailSelect {

    img{
        width: 50%;
    }
}

//display of i symbol in detaild for audio and video
.ehiveInfo:hover{
    text-decoration: none;
    color: $eh-orange;;
}

.originalIcon {

    svg {
        align-items: center;  
    }
}

.origView{
    margin-left: 0.25rem;
}

.ogThumb{
    width: 45px;
    height: 35;
}

// Accordion show/hide for media content

.mediaView {
    margin-top: 0.5rem !important;
    margin-bottom: 0.75rem !important;

    &:first-of-type{
        margin-top: 0 !important;
    }

    .accordion {
        background-color: #eee;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        transition: 0.4s;
    }
      
    .active, .accordion:hover {
        background-color: #ccc; 
    }
    
    .panel {
        display: none;
        background-color: white;
        overflow: hidden;

        &.showPanel{
            display: block !important; 
        }
    }
    
    .accordion:after {
        font-family: 'FontAwesome'; 
        content: '\02795'; /* Unicode character for "plus" sign (+) */
        float: right;
        margin-left: 5px;
    }
    
    .active:after {
        font-family: 'FontAwesome'; 
        content: "\2796"; /* Unicode character for "minus" sign (-) */
    }

    .pdfView {
        background-color: #F8F8F8;

        .pdfFileView{
            margin: 1rem;
        }
    }

    .eh-object-high-res-images-grid{
        margin: 0;
    }
}

/* Video and Audio Details display search -----------------------------------------------------end */


/*Content end*/